<template>
	<router-link
		:to="{ name: 'product', params: { slug: data.slug } }"
		class="product-card">
		<div class="product-card__image image image--cover">
			<img
				:src="data.primaryImage.url"
				:alt="data.name" />
		</div>
		<div class="product-card__content content">
			<div>
				<div class="content__name">{{ data.name }}</div>
				<div v-if="offerPrice && offerPrice > 0" class="content__price price price-offer">
					{{ offerPrice.toFixed(3) }}
				</div>
				<div
					class="content__price price"
					:class="{ 'price-off' : offerPrice && offerPrice > 0 }"
				>
					{{ data.price ? data.price.toFixed(3) : data.secondPrice.toFixed(3) }}
				</div>
			</div>
			<div
				class="content__add"
				@click.prevent="addToCart">
				<i class="fas fa-plus"></i>
			</div>
		</div>
	</router-link>
</template>

<script>
import { getOfferPrice } from '@/helpers/helpers'
export default {
	props: {
		data: { type: Object },
	},

	computed: {
		offerPrice() {
			return getOfferPrice(
				this.data.offers,
				this.data.price ? this.data.price : this.data.secondPrice
			)
		}
	},

	methods: {
		addToCart() {
			this.$eventBus.$emit('show-add-to-cart-dialog', this.data);
		},
	}
};
</script>

<style lang="scss" scoped>
@use '@/style' as *;
.product-card {
	display: block;
	box-shadow: map($shadows, 0);
	&:hover {
		box-shadow: map($shadows, 1);
	}
	padding: 0.5rem;
	background-color: white;
	border-radius: calc(10px + 0.5rem);
	&__image {
		border-radius: 10px;
		overflow: hidden;
		aspect-ratio: 1 / 1;
		img {
		}
	}
	&__content {
		padding: 0.5rem;
	}

	.content {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;

		&__name {
			font-size: 0.9rem;
			line-height: 1.3em;
			height: 2.6em;
			@media (max-width: 360px) {
				font-size: 0.8rem;
				height: 3.9em;
			}
		}
		&__price {
			font-size: 1rem;
			font-weight: 600;
			&::after {
				margin-right: 3px;
				font-weight: 400;
				content: 'دك';
			}
		}
		&__add {
			min-width: 2.3rem;
			max-width: 2.3rem;
			height: 2.3rem;
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 50%;
			background-color: map($colors, 'sec');
			color: white;
			* {
				font-weight: 400;
				font-size: 1.4rem;
			}
			transition: 0.4s;
			&:hover {
				transform: scale(1.05);
				box-shadow: map($shadows, 1);
			}
		}
	}
}
</style>
