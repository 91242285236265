import HomeView from '@/views/HomeView';
// import ContactView from '@/views/ContactView';

export default [
	{
		path: '',
		name: 'home',
		component: HomeView,
		// component: () => import('@/views/HomeView'),
	},
	{
		path: '/product/:slug',
		name: 'product',
		component: () => import('@/views/products/ProductView'),
	},
	{
		path: '/products',
		name: 'all-products',
		component: () => import('@/views/products/AllProducts'),
	},
	{
		path: '/cart',
		name: 'cart',
		component: () => import('@/views/cart/CartView'),
	},
	{
		path: '/validation',
		name: 'validation',
		component: () => import('@/views/cart/ValidationView'),
	},
	{
		path: '/thanks',
		name: 'thank-you',
		component: () => import('@/views/cart/ThankYouView'),
	},
	{
		path: '/check-out',
		name: 'check-out',
		component: () => import('@/views/cart/CheckOutView'),
	},
	{
		path: '/login',
		name: 'login',
		component: () => import('@/views/auth/LoginView'),
	},
	{
		path: '/signup',
		name: 'signup',
		component: () => import('@/views/auth/SignupView'),
	},
	{
		path: '/otp',
		name: 'otp',
		component: () => import('@/views/auth/PhoneView'),
	},
	{
		path: '/valid',
		name: 'valid',
		component: () => import('@/views/auth/ValidateView'),
	},
	{
		path: '/reset-password',
		name: 'reset-password',
		component: () => import('@/views/auth/ResetPassword'),
	},
	{
		path: '/profile',
		name: 'profile',
		component: () => import('@/views/auth/ProfileView'),
	},
	{
		path: '/contact',
		name: 'contact',
		// component: ContactView,
		component: () => import('@/views/ContactView'),
	},
	{
		path: '/referral',
		name: 'referral',
		component: () => import('@/views/ReferralView'),
	},
	{
		path: '/projects',
		name: 'all-projects',
		component: () => import('@/views/projects/AllProjects.vue'),
	},
	{
		path: '/projects/:slug',
		name: 'projects-view',
		component: () => import('@/views/projects/ProjectView.vue'),
	},
	{
		path: '*',
		name: '404',
		component: () => import('@/views/404View'),
	},
];
