<template>
	<div
		class="dialog-layout"
		@click.self="$emit('close')"
		v-if="product">
		<div class="dialog-card">
			<div
				class="dialog-card__close"
				@click="$emit('close')">
				<i class="fas fa-xmark"></i>
			</div>

			<div class="dialog-card__title">
				<img
					src="@/assets/check.gif"
					alt="" />
				تم إضافة المنتج إلى سلتك
			</div>
			<div class="other-product">
				<div class="other-product__content">
					<router-link :to="{ name: 'product', params: { slug: product.slug } }">
						<img
							:src="product.primaryImage.url"
							alt="" />
					</router-link>
					<div>
						<div class="other-product__name">
							{{ product.name }}
						</div>
						<div class="other-product__price">
							{{ price }}
						</div>
					</div>
				</div>
				<div class="other-product__actions">
					<router-link
						v-if="$route.name !== 'all-products'"
						:to="{ name: 'all-products', query: { id: product.categoriesIds[0] } }"
						class="button button--sec-outlined">
						متابعة التسوق
					</router-link>
					<div
						v-else
						@click="$emit('close')"
						:to="{ name: 'all-products', query: { id: product.categoriesIds[0] } }"
						class="button button--sec-outlined">
						متابعة التسوق
					</div>
					<div class="spacer"></div>
					<div
						v-if="$route.name === 'cart'"
						@click="$emit('close')"
						class="button button--sec">
						الدفع
					</div>
					<router-link
						v-else
						:to="{ name: 'cart' }"
						class="button button--sec">
						الدفع
					</router-link>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';

export default {
	props: {
		product: { type: Object },
		price: { type: String },
	},

	watch: {
		$route() {
			this.$emit('close');
		},
	},

	computed: {
		// interestingProduct() {
		// 	const findInTwoArray = (arr1, arr2) => {
		// 		return Boolean(arr1.find((e) => arr2.includes(e)));
		// 	};

		// 	const related = this.products.filter((e) => this.product.relatedProductIds.includes(e.id));

		// 	let fromSameCategory = [];
		// 	fromSameCategory = this.products.filter((e) =>
		// 		findInTwoArray(e.categoriesIds, this.product.categoriesIds),
		// 	);
		// 	fromSameCategory = fromSameCategory.filter((e) => !related.find((r) => r.id === e.id));

		// 	console.log([...related, ...fromSameCategory]);

		// 	const productInCartIds = this.cart.products.map((e) => e.product.id);
		// 	return [...related, ...fromSameCategory].find((e) => !productInCartIds.includes(e.id));
		// },
		...mapState({
			products: (state) => state.products.products,
			cart: (state) => state.cart.cart,
		}),
	},
};
</script>

<style lang="scss" scoped>
.dialog-card__title img {
	width: 4rem;
	height: 4rem;
	object-fit: contain;
	vertical-align: middle;
	filter: hue-rotate(297.9deg) saturate(429%) contrast(230%);
}
.other-product {
	&__content {
		display: flex;
		align-items: center;
		padding: 1rem 0;
	}
	&__name {
		font-size: 1.1rem;
		font-weight: 600;
		// text-align: center;
	}
	&__price {
		font-weight: 600;
		// text-align: center;
		margin-bottom: 2rem;
		&::after {
			content: 'د.ك';
		}
	}
	img {
		width: 150px;
		border-radius: 10px;
		margin-inline-end: 1rem;
	}
	&__actions {
		display: flex;
		.spacer {
			min-width: 1rem;
		}
		> .button {
			flex: 1;
			text-align: center;
		}
	}
}
</style>
