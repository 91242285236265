<template>
	<div
		ref="snackbar"
		@click.self="
			() => {
				if (typeof snackbarAction === 'function') {
					showSnackbar = false;
					snackbarAction();
				}
			}
		"
		v-if="showSnackbar"
		@mouseenter="mouseenterSnackbar"
		@mouseleave="mouseleaveSnackbar"
		class="snack-bar"
		:class="{ notError: !isError, cp: typeof snackbarAction === 'function' }">
		{{ message }}
		<div
			class="snack-bar__close"
			@click="showSnackbar = false">
			<i class="fas fa-times"></i>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			showSnackbar: false,
			message: '',
			isError: true,
			snackbarAction: null,
			snackbarTime: null,
		};
	},

	watch: {
		showSnackbar() {
			if (this.showSnackbar) {
				this.$nextTick(() => {
					const beginSliding = (e) => {
						slider.onpointermove = slide;
						slider.setPointerCapture(e.pointerId);
					};

					const stopSliding = (e) => {
						slider.onpointermove = null;
						slider.releasePointerCapture(e.pointerId);
						slider.style.left = `${startPosition}px`;
						if (e.clientX > startPosition + 100 || e.clientX < startPosition - 100)
							this.showSnackbar = false;
					};

					const slide = (e) => {
						slider.style.left = `${e.clientX}px`;
					};

					const slider = this.$refs.snackbar;

					const width = slider.clientWidth;
					const startPosition = slider.getBoundingClientRect().x + width / 2;

					slider.onpointerdown = beginSliding;
					slider.onpointerup = stopSliding;
				});
			}
		},
	},

	methods: {
		showSnackbarFun() {
			clearTimeout(this.snackbarTime);
			this.showSnackbar = true;
			this.snackbarTime = setTimeout(() => {
				this.showSnackbar = false;
			}, 5000);
		},
		mouseenterSnackbar() {
			clearTimeout(this.snackbarTime);
		},
		mouseleaveSnackbar() {
			this.snackbarTime = setTimeout(() => {
				this.showSnackbar = false;
			}, 5000);
		},
	},

	mounted() {
		this.$eventBus.$on('snackbar', ({ isError = true, message, action }) => {
			this.message = message;
			this.isError = isError;
			this.snackbarAction = action?.bind(this);

			this.showSnackbarFun();
		});
	},
};
</script>

<style scoped lang="scss">
@use '@/style/index.scss' as *;

.snack-bar {
	position: fixed;
	right: 1rem;
	left: 1rem;
	bottom: 3rem;
	margin: auto;
	width: min(400px, 90vw);
	padding: 0.5rem 1rem;
	padding-right: 3.5rem;

	color: white;
	background-color: map($colors, 'main');
	border-radius: 5px;
	box-shadow: map($shadows, 2);
	z-index: 999;

	&.notError {
		background-color: green;
	}

	animation: show 0.5s forwards;
	@keyframes show {
		from {
			opacity: 0;
		}
		to {
			opacity: 1;
		}
	}

	&__close {
		cursor: pointer;
		position: absolute;
		top: 50%;
		right: 1.7rem;
		transform: translateY(-50%);
	}
}
</style>
