<template>
	<div
		v-if="loadingProducts"
		class="loader-container">
		<b-loader spinner></b-loader>
	</div>
	<div v-else>
		<header class="header">
			<div class="container">
				<div class="header-content page-p">
					<div class="header-content__sanad">سنـــد</div>
					<div class="header-content__description">
						سندك الحقيقي
						<br />
						في عالم التسويق
					</div>
					<router-link
						:to="{ name: 'all-products' }"
						class="button button--main">
						ابدأ رحلتك الآن
						<i
							class="fas fa-arrow-left-long"
							style="margin-right: 5px"></i>
					</router-link>
				</div>
				<div class="header-image">
					<div class="">
						<img
							src="@/assets/Shape.png"
							alt="" />
					</div>
				</div>
			</div>
		</header>

		<section class="categories-section page-p">
			<div class="container">
				<div class="home-title-container">
					<h2 class="home-title">
						شبيك لبيك<span class="big"><br /></span><span class="small">..</span>التسويق صار بين
						يديك
					</h2>
					<p class="mb-3">
						بكل حب..خدماتنا التسويقية صارت بين يديك، جمعناها لك <br />وبسطناها حتى ترتاح من وجع
						الراس!
					</p>
				</div>

				<b-loader
					v-if="loadingCategories"
					inline>
				</b-loader>
				<div
					v-else
					class="categories-content">
					<div
						class="row-padding"
						v-for="item in categories"
						:key="item.id">
						<!-- :to="{ name: 'all-products', query: { id: item.id } }" -->
						<div
							@click="route(item)"
							:class="{ selected: selectedCategory == item.id }"
							class="categories-item">
							<div class="categories-item__image image">
								<img
									:src="item.image"
									:alt="item.name" />
							</div>
							<div class="categories-item__name">{{ item.name }}</div>
							<div class="categories-item__more">
								المزيد
								<i
									style="font-size: 0.6rem; margin-inline-start: 5px"
									class="fas fa-arrow-left-long"></i>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>

		<section class="products-section page-p">
			<div class="container">
				<div class="home-title-container">
					<h2 class="home-title">
						<span class="big">سنـــــــــــــد<br />يسند أعمالك</span>
						<span class="small">سنــــــد..يسند أعمالك</span>
					</h2>
					<p class="mb-3">
						نعم يسندها<br />
						لأن هدفنا من بدينا إبداعنا إننا نكون سند أعمالك، سند مشاريعك، سند رحلتك الريادية
						الرهيبة !
					</p>
				</div>
				<router-link
					:to="{ name: 'all-products' }"
					class="button button--sec">
					كل المنتجات
				</router-link>

				<div class="products-grid">
					<div
						class="row-padding"
						v-for="item in [
							...products.filter((e) => e.inHomePage),
							...products.filter((e) => !e.inHomePage),
						].slice(0, maxProductCount)"
						:key="item.id">
						<product-card :data="item"></product-card>
					</div>
				</div>
				<button
					v-if="products.length > maxProductCount"
					class="button button--main"
					@click="changeProductCount(true)">
					حمل المزيد من المنتجات
				</button>
			</div>
		</section>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import ProductCard from '@/components/ProductCard.vue';

export default {
	props: {
		loadingCategories: { type: Boolean, default: false },
		loadingProducts: { type: Boolean, default: false },
	},

	components: { ProductCard },

	data() {
		return {
			maxProductCount: 6,
			selectedCategory: null,
		};
	},

	computed: {
		...mapState({
			products: (state) => state.products.products,
			categories: (state) => state.categories.categories,
		}),
	},

	methods: {
		route(item) {
			if (!this.selectedCategory) {
				this.selectedCategory = item.id;
				setTimeout(() => {
					this.selectedCategory = null;
					this.$router.push({ name: 'all-products', query: { id: item.id } }, () => {});
				}, 500);
			}
		},
		changeProductCount(add) {
			if (add) {
				this.maxProductCount += 4;
			} else {
				if (window.innerWidth >= 992) {
					this.maxProductCount = 8;
				} else {
					this.maxProductCount = 6;
				}
			}
		},
	},

	mounted() {
		this.changeProductCount();

		// window.addEventListener('resize', () => {
		// 	this.changeProductCount();
		// });
	},

	metaInfo() {
		return {
			title: 'سند',
		};
	},
};
</script>

<style lang="scss" scoped>
@use '@/style' as *;

.home-title {
	font-weight: 700;
	font-size: 1.35rem;
	color: map($colors, 'sec');
	margin-bottom: 0.7rem;
	@media (min-width: 1200px) {
		font-size: 2rem;
		margin-bottom: 1.6rem;
	}
}

.home-title-container {
	.big {
		display: none;
	}
	@media (min-width: 992px) {
		display: flex;
		align-items: center;
		.big {
			display: initial;
		}
		.small {
			display: none;
		}
		.home-title {
			max-width: 19rem;
			min-width: 19rem;
			@media (min-width: 1200px) {
				max-width: 23rem;
				min-width: 23rem;
			}
		}
	}
}

.main-content {
	padding-bottom: 2rem;
}

.header {
	background-image: url('@/assets/header.png');
	background-position: calc(100% + 3rem) 0;
	background-size: 10rem;
	background-color: map($colors, 'sec');
	padding-top: 10rem;
	@media (min-width: 992px) {
		background-image: url('@/assets/header-big.png');
		background-size: auto 120%;
		padding-top: 14rem;
	}

	.container {
		@media (min-width: 768px) {
			display: flex;
			justify-content: space-between;
			// align-items: flex-end;
		}
	}

	&-content {
		margin-bottom: 2rem;
		&__sanad {
			font-weight: 900;
			font-size: 6rem;
			line-height: 7rem;
			color: map($colors, 'alt');
			@media (min-width: 992px) {
				font-size: 7.5rem;
				margin-bottom: 1rem;
			}
			@media (min-width: 1200px) {
				font-size: 9.5rem;
				margin-bottom: 1rem;
			}
		}
		&__description {
			font-weight: 800;
			font-size: 2.2rem;
			line-height: 1.6em;
			color: white;
			margin-bottom: 2rem;
			@media (min-width: 992px) {
				font-size: 3rem;
			}
			@media (min-width: 1200px) {
				font-size: 3rem;
			}
			@media (min-width: 1500px) {
				font-size: 3.7rem;
				margin-bottom: 4rem;
			}
		}
	}

	&-image {
		align-self: flex-end;
		height: 300px;
		text-align: end;
		@media (min-width: 992px) {
			height: 50vh;
		}
		@media (min-width: 1200px) {
			height: 60vh;
		}
		@media (min-width: 1500px) {
			height: 70vh;
		}
		div {
			width: 100%;
			height: 100%;
			img {
				height: 100%;
			}
		}
	}
}

.categories-section {
	padding-top: 2.3rem;
	padding-bottom: 5rem;
	background-color: map($colors, 'background-2');
}

.categories-content {
	display: flex;
	flex-wrap: wrap;

	$dark: #dce5fd;
	.row-padding .categories-item {
		background-color: white;
	}
	.row-padding:first-child {
		.categories-item {
			background-color: $dark;
		}
	}
	@media (max-width: 992px) {
		.row-padding:last-child {
			.categories-item {
				background-color: $dark;
			}
		}
	}
	@media (min-width: 992px) {
		.row-padding:nth-child(3) {
			.categories-item {
				background-color: $dark;
			}
		}
	}

	.categories-item {
		display: block;
		border-radius: 12px;
		padding: 1.5rem;
		&__image {
			width: 4.4rem;
			height: 4.4rem;
			margin-bottom: 1.5rem;
		}
		&__name {
			font-weight: 600;
			margin-bottom: 0.6rem;
		}
		&__more {
			font-size: 0.9rem;
			color: map($colors, 'sec');
		}
		transition: 0.2s;
		&.selected,
		&:hover {
			cursor: pointer;
			background-image: url('@/assets/header.png');
			background-position: 0 0;
			background-size: 4rem;
			background-color: map($colors, 'sec') !important;
			color: white !important;
			* {
				color: white !important;
			}
			box-shadow: map($shadows, 0);
		}
	}
	.row-padding {
		width: 50%;
		padding: 0.5rem;
		@media (min-width: 992px) {
			width: 25%;
		}
	}
}

.products-section {
	padding-top: 2.3rem;
	padding-bottom: 5rem;

	background-image: url('@/assets/product.png');
	background-position: -3rem -1px;
	background-size: 10rem;
	background-repeat: repeat-y;
	.products-grid {
		margin: 2rem 0;
	}
}
</style>
