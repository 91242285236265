import * as types from '../mutations';
import axios from '@/axios';

export default {
	namespaced: true,

	state: {
		products: [],
	},

	actions: {
		fetchInHomePage({ commite }) {
			return axios.get('/home').then((res) => {
				return res.data;
			});
		},
		// fetch all products
		fetchAll({ commit }) {
			return axios
				.get('/products', {
					params: {
						useOrder: true,
					},
				})
				.then((res) => {
					commit(types.PRODUCTS_STORE, res.data);
					return res.data;
				});
		},

		// return the products. and if it's empty fetch products
		fetchAllOrGet({ commit, state }) {
			return state.products?.length
				? state.products
				: axios
						.get('/products', {
							params: {
								useOrder: true,
							},
						})
						.then((res) => {
							commit(types.PRODUCTS_STORE, res.data);
							return res.data;
						});
		},

		// fetch one products by it's slug
		fetchBySlug({ commit }, { slug }) {
			return axios.get(`products/${slug}`).then((res) => res.data);
		},

		// get one products by it's slug, and if it's not found fetch it
		fetchByStateOrSlug({ commit, state }, { slug }) {
			let product = null;
			if (state.products) {
				product = state.products.find((e) => e.slug === slug);
			}
			return product || axios.get(`/products/${slug}`).then((res) => res.data);
		},
	},

	mutations: {
		// fill the state with products
		[types.PRODUCTS_STORE](state, products) {
			state.products = products.filter((e) => e.isActive);
		},
	},

	getters: {
		// return one products by it's slug
		getBySlug: (state) => (slug) => state.products.find((e) => e.slug === slug),
	},
};
