import store from "@/store";
import moment from "moment";


export function getActiveOffer(targetOffers) {
    const allOffers = store.state.offers.offers;
    if (targetOffers && targetOffers.length > 0 && allOffers.length > 0) {
        let offers =
            targetOffers
                .map(id => allOffers.find(c => c.id === id))
                .filter(Boolean)
                // remove out of date offers
                .filter(c => {
                    return c.fromDate && c.toDate
                        ? moment().isBetween(c.fromDate, c.toDate, 'day', '[]')
                        : true
                })
                .map(c => ({
                    ...c,
                    // get remaining duration
                    remainingDuration:
                        c.fromDate && c.toDate
                            ? new Date(c.toDate) - new Date(c.fromDate)
                            : Infinity
                }));
        offers = offers.sort((a, b) => b.remainingDuration > a.remainingDuration > 0 ? -1 : 1);
        
        if (!offers.length) return null;

        const activeOffer = offers[offers.length - 1];
        return activeOffer;
    }
    return null;
}
export function getOfferPrice(targetOffers, originalPrice, isUpSelling) {
    const activeOffer = getActiveOffer(targetOffers);
    
    if (!activeOffer) return null;
    if (isUpSelling && activeOffer.fixedValue) return null;
    
    const offAmount =
        activeOffer.percentValue
            ? ((activeOffer.percentValue / 100) * originalPrice)
            : activeOffer.fixedValue || 0
    const finalPrice = originalPrice - offAmount;
    return finalPrice >= 0 ? finalPrice : 1e-5;
}