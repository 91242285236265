import Vue from 'vue';
import Vuex from 'vuex';

import aboutus from './modules/aboutus';
import albums from './modules/albums';
import categories from './modules/categories';
import contacts from './modules/contacts';
import feedback from './modules/feedback';
import news from './modules/news';
import products from './modules/products';
import sliders from './modules/sliders';
import auth from './modules/auth';
import cart from './modules/cart';
import orders from './modules/orders';
import filters from './modules/filters';
import projects from './modules/projects';
import offers from './modules/offers';
import settings from './modules/settings';
import axios from '@/axios';

Vue.use(Vuex);

export default new Vuex.Store({
	actions: {
		error(_, error) {
			const formData = new FormData();
			formData.append('section', error.section);
			try {
				formData.append(
					'error',
					JSON.stringify({
						err: error.err.toString(),
						data: error.err.response?.data,
						params: error.err.config?.params,
						headers: error.err.config?.headers,
						body: error.err.config?.data,
					}),
				);
			} catch (err) {
				formData.append('error', error.err);
			}

			axios.post('/error-log', formData);
		},
	},
	modules: {
		aboutus,
		albums,
		categories,
		contacts,
		feedback,
		news,
		products,
		sliders,
		auth,
		cart,
		orders,
		filters,
		projects,
		offers,
		settings,
	},
});
