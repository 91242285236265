<template>
	<div
		v-if="!['otp'].includes($route.name)"
		id="navbar"
		:class="{
			'is-down':
				isFarFromTop || ['login', 'signup', 'reset-password', 'otp', '404'].includes($route.name),
		}">
		<div class="container">
			<!-- skip to main content -->
			<a
				href="#main"
				class="skip-to">
				skip to main content
			</a>

			<!-- go to top -->
			<div
				class="go-to-top show"
				v-if="contacts.whatsapp">
				<a
					:href="'https://wa.me/' + contacts.whatsapp"
					target="_blank">
					<i class="fab fa-whatsapp"></i>
				</a>
			</div>

			<!-- main nav -->
			<div class="main-nav">
				<!-- brand -->
				<div class="brand">
					<router-link
						:to="{ name: 'home' }"
						alt="link">
						<div class="image">
							<img
								src="@/assets/Logo/white-logo.png"
								alt="brand logo" />
						</div>
					</router-link>
				</div>

				<!-- nav links -->
				<nav
					class="links-menu"
					:class="{ show: showNav }">
					<ul>
						<li>
							<router-link
								:to="{ name: 'home' }"
								target="link">
								الرئيسية
							</router-link>
						</li>

						<li
							ref="categoriesDropdown"
							tabindex="0"
							class="with-dropdown"
							role="button"
							:class="{ down: showDropdownCategories }"
							@click="showDropdownCategories = !showDropdownCategories"
							@keydown.enter="showDropdownCategories = !showDropdownCategories">
							<span class="dropdown-icon">
								الأقسام
								<i class="fas fa-angle-down"></i>
							</span>
							<div
								class="dropdown-container"
								v-if="showDropdownCategories && !loadingCategories">
								<ul>
									<li
										v-for="item in categories"
										:key="item.id">
										<router-link
											:to="{ name: 'all-products', query: { id: item.id } }"
											alt="link">
											{{ item.name }}
										</router-link>
									</li>
								</ul>
							</div>
						</li>
						<li>
							<router-link
								:to="{ name: 'all-projects' }"
								target="link">
								معرض الأعمال
							</router-link>
						</li>
						<!-- <li
							ref="projectsDropdown"
							tabindex="0"
							class="with-dropdown"
							role="button"
							:class="{ down: showDropdownProjects }"
							@click="showDropdownProjects = !showDropdownProjects"
							@keydown.enter="showDropdownProjects = !showDropdownProjects">
							<span class="dropdown-icon">
								المشاريع
								<i class="fas fa-angle-down"></i>
							</span>
							<div
								class="dropdown-container"
								v-if="showDropdownProjects">
								<ul>
									<li>
										<router-link
											:to="{}"
											alt="link">
											category 1
										</router-link>
									</li>
									<li>
										<router-link
											:to="{}"
											alt="link">
											category 2
										</router-link>
									</li>
									<li>
										<router-link
											:to="{}"
											alt="link">
											category 3
										</router-link>
									</li>
								</ul>
							</div>
						</li> -->
						<!-- <li>
							<router-link
								:to="{ name: 'about' }"
								target="link">
								عن سند
							</router-link>
						</li> -->
						<li>
							<router-link
								class="button button--outlined"
								:to="{ name: 'contact' }"
								alt="link">
								تواصل معنا
							</router-link>
						</li>
					</ul>
				</nav>

				<!-- nav toggle (for mobile) -->
				<div
					class="hamburger touch"
					ref="navbar">
					<button
						:title="!showNav ? 'open navbar' : 'close navbar'"
						:aria-label="!showNav ? 'open navbar' : 'close navbar'"
						:aria-expanded="showNav ? true : false"
						class="menu-trigger"
						:class="{ active: showNav }"
						@click="showNav = !showNav">
						<span></span>
						<span></span>
						<span></span>
					</button>
				</div>

				<!-- actions list -->
				<div class="actions-menu">
					<ul>
						<li>
							<router-link
								class="nav-icon"
								:to="{ name: 'profile' }"
								alt="link">
								<i class="fas fa-user"></i>
								<span class="visually-hidden"> profile </span>
							</router-link>
						</li>
						<li>
							<router-link
								class="nav-icon"
								:to="{ name: 'cart' }"
								alt="link">
								<i class="fas fa-cart-shopping"></i>
								<span class="visually-hidden"> cart </span>
								<span
									class="badges"
									v-if="cart?.products.length">
									{{ cart.products.length }}
								</span>
							</router-link>
						</li>
						<!-- <li>
							<router-link
								class="nav-icon"
								:to="{}"
								alt="link">
								<i class="fas fa-magnifying-glass"></i>
								<span class="visually-hidden"> language </span>
							</router-link>
						</li> -->
					</ul>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';

export default {
	props: {
		loadingCategories: { type: Boolean, default: true },
	},

	name: 'MainNav',

	data() {
		return {
			showNav: false,
			showDropdownCategories: false,
			showDropdownProjects: false,

			isFarFromTop: false,
		};
	},

	computed: {
		...mapState({
			categories: (state) => state.categories.categories,
			cart: (state) => state.cart.cart,
			contacts: (state) => state.contacts.contacts,
		}),
	},

	methods: {
		goToTop() {
			window.scrollTo({ top: 0, behavior: 'smooth' });
		},
	},

	mounted() {
		window.addEventListener('click', (e) => {
			if (this.$refs.categoriesDropdown?.contains(e.target)) {
				this.showDropdownProjects = false;
				return;
			}
			if (this.$refs.projectsDropdown?.contains(e.target)) {
				this.showDropdownCategories = false;
				return;
			}
			if (this.$refs.navbar?.contains(e.target)) {
				this.showDropdownCategories = false;
				this.showDropdownProjects = false;
				return;
			}
			this.showNav = false;
			this.showDropdownCategories = false;
			this.showDropdownProjects = false;
		});

		window.addEventListener('scroll', (e) => {
			this.isFarFromTop = window.pageYOffset > 200;
		});
	},
};
</script>

<style lang="scss" scoped>
@use '@/style' as *;

.nav-icon {
	padding: 10px;
	* {
		font-size: 1.4rem;
		color: white;
	}
}
.links-menu {
	margin-left: auto;
}
#navbar {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	z-index: 10;

	padding: 0 25px;
	> div {
		transition: 0.3s;
		padding: 3rem 0 2rem;
		border-bottom: 1px solid white;
	}
	&.is-down {
		background-color: map($colors, 'sec');
		box-shadow: map($shadows, 2);
		> div {
			padding: 1rem 0;
			border-bottom: 1px solid transparent;
		}
	}
}
.dropdown-icon i {
	font-size: 0.5rem;
	transition: 0.2s;
}
.down .dropdown-icon i {
	transform: rotate(180deg);
}
.badges {
	width: 1rem;
	height: 1rem;
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: -5px;
	right: 5px;
	background-color: map($colors, 'main');
	color: white !important;
	font-size: 0.6rem !important;
	border-radius: 50%;
}
</style>

<style scoped lang="scss">
@use '@/style' as *;

$breakpoint: 992px; // nav turn into mobile
$order: (
	'brand': 1,
	'links-menu': 2,
	'hamburger': 3,
	'actions-menu': 4,
);
$mobile-order: (
	'brand': 2,
	'links-menu': 4,
	'hamburger': 1,
	'actions-menu': 3,
);

@mixin brand {
	height: 3.5rem;
	width: 5rem;
	margin-left: auto;
	margin-right: auto;
	@media (min-width: 992px) {
		margin-right: 10px;
		margin-left: 3rem;
	}
	@media (min-width: 1200px) {
		margin-left: 5rem;
	}
}

@mixin links-item {
	font-size: 0.8rem;
	color: white;
}
@mixin links-item-hover {
	color: map($colors, 'alt');
}
$links-item-margin: (
	'big': 4rem,
	1200px: 2rem,
	765px: 0,
);

$absolute-nav: true;
$mobile-links-end: translate(0, 0); // for absolute nav
$mobile-links-padding: 1rem; // for static nav
@mixin mobile-links {
	text-align: center;
	transition: 0.5s ease-in-out;
	flex-direction: column;
	overflow-y: auto;
	@if ($absolute-nav) {
		background-color: #dce5fd;
		padding: 2rem 1rem 3rem;
		border-radius: 5px;

		transform: translate(0, -100vh);
		top: calc(100% + 1rem);
		right: 0;
		left: 0;
		z-index: 2;
		box-shadow: map($shadows, 2);
		max-height: calc(100vh + 3rem);
	} @else {
		max-height: 0vh;
	}
}
@mixin mobile-links-item {
	font-size: 1.2rem;
	&:not(:last-child) {
		margin-bottom: 2.4rem;
	}
	color: black !important;
	* {
		border-color: black !important;
		color: black !important;
	}
}

@mixin actions-item {
	position: relative;
	* {
		font-size: 1.1rem;
	}
}
@mixin actions-item-hover {
	* {
		color: map($colors, 'alt');
	}
}
$actions-item-margin: (
	'big': 0.5rem,
	765px: 0.3rem,
	576px: 0rem,
);

$hamburger-size: 1.5rem;

@mixin dropdown {
	background-color: #dce5fd;
	padding: 1rem;
	border-radius: 6px;
	color: black;
	width: 170px;
	text-align: center;
	box-shadow: map($shadows, 1);
}
@mixin dropdown-item {
	margin-bottom: 0.3rem;
}
@mixin dropdown-item-hover {
	color: map($colors, 'sec');
	text-decoration: underline;
}

@keyframes dropdownAnimation {
	from {
		max-height: 0;
		transform: translateY(-8px);
	}
	to {
		max-height: 500px;
		transform: translateY(0);
	}
}

.go-to-top {
	a {
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	position: fixed;
	bottom: 2rem;
	left: 2rem;

	width: 3.5rem;
	height: 3.5rem;
	display: none;
	&.show {
		display: flex;
	}
	align-items: center;
	justify-content: center;
	color: white;
	background-color: #4cb050;
	box-shadow: map($shadows, 0);
	border-radius: 50%;
	* {
		font-size: 2rem;
	}
	@media (min-width: 992px) {
		width: 5rem;
		height: 5rem;
		* {
			font-size: 3rem;
		}
	}
}

/**************/
.main-nav {
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: relative;
}

.brand {
	@include brand;
	order: map($order, 'brand');
	.image {
		width: 100%;
		height: 100%;
	}
}

.hamburger {
	font-size: $hamburger-size;
	margin-inline-end: 20px;
	order: map($order, 'hamburger');
	@media (min-width: $breakpoint) {
		display: none;
	}
}

.links-menu {
	order: map($order, 'links-menu');

	> ul {
		display: flex;
		align-items: center;
		> li {
			@include links-item;
			&:not(.fav-link):hover {
				@include links-item-hover;
			}
		}
		> li:not(:last-child) {
			@each $breakpoint, $value in $links-item-margin {
				@if ($breakpoint == 'big') {
					@include marginEnd($value);
				} @else {
					@media (max-width: $breakpoint) {
						@include marginEnd($value);
					}
				}
			}
		}
	}

	@media (max-width: $breakpoint) {
		@if ($absolute-nav) {
			position: absolute;
			&.show {
				transform: $mobile-links-end;
			}
		} @else {
			width: 100%;
			padding: 0;
			&.show {
				max-height: 90vh;
				padding: $mobile-links-padding;
			}
		}
		@include mobile-links;
		> ul {
			flex-direction: column;
			> li {
				@include mobile-links-item;
			}
		}
	}
}

.actions-menu {
	order: map($order, 'actions-menu');

	> ul {
		display: flex;
		align-items: center;
		> li {
			@include actions-item;
			&:hover {
				@include actions-item-hover;
			}
		}
		> li:not(:last-child) {
			@each $breakpoint, $value in $actions-item-margin {
				@if ($breakpoint == 'big') {
					@include marginEnd($value);
				} @else {
					@media (max-width: $breakpoint) {
						@include marginEnd($value);
					}
				}
			}
		}
	}
}

.with-dropdown > .dropdown-container {
	animation: dropdownAnimation 0.3s forwards;
	overflow: hidden;

	@media (min-width: $breakpoint) {
		position: absolute;
		top: 100%;
		z-index: 2;
		box-shadow: map($shadows, 1);
	}
	@media (max-width: $breakpoint) {
	}
	@include dropdown;
	li {
		@include dropdown-item;
		&:hover {
			@include dropdown-item-hover;
			cursor: pointer;
		}
	}
}

@each $part, $order in $mobile-order {
	@media (max-width: $breakpoint) {
		.#{$part} {
			order: $order;
		}
	}
}

.skip-to {
	position: absolute;
	left: 0;
	z-index: -999;
	padding: 1em;
	background-color: black;
	color: white;
	font-size: 14px;
	top: -5em;
}
.skip-to:focus-visible {
	top: 0;
	z-index: 999;
}
/**************************************************** */
// all rights to https://codepen.io/nxworld/pen/RPLLbm
.menu-trigger {
	padding: 8px;
	position: relative;
	width: 1em;
	height: 1em;
	appearance: none;
	transition: all 0.6s;
	vertical-align: middle;
	span {
		transition: all 0.6s;
		position: absolute;
		left: 0;
		width: 100%;
		height: 0.14em;
		color: inherit;
		background-color: white;
		border-radius: 0.14em;
		&:nth-of-type(1) {
			top: 0;
		}
		&:nth-of-type(2) {
			top: 0.51em;
		}
		&:nth-of-type(3) {
			bottom: 0;
		}
	}
	&.active {
		transform: rotate(270deg);
		span {
			&:nth-of-type(1) {
				transform: translateY(0.5em) rotate(-45deg);
			}
			&:nth-of-type(2) {
				transform: translateY(0) rotate(45deg);
			}
			&:nth-of-type(3) {
				transition: 0.2s;
				opacity: 0;
			}
		}
	}
}
</style>
