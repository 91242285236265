import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import axios from '@/axios';
import interceptors from './axios/interceptors';
import './helpers/eventBus';
import '@/emailAxios';

import BLoader from '@/components/BLoader';
import VueMeta from 'vue-meta';
import VueEasyLightbox from 'vue-easy-lightbox';
import 'vue-easy-lightbox/dist/external-css/vue-easy-lightbox.css';

Vue.component('b-loader', BLoader);

interceptors(axios, store, router);

Vue.config.productionTip = false;

Vue.component('vue-easy-lightbox', VueEasyLightbox);
Vue.use(VueMeta);
new Vue({
	router,
	store,
	render: (h) => h(App),
}).$mount('#app');
